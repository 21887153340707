import { BaseApi } from "../../../../shared/api/base";

export class EventApi extends BaseApi {
  constructor() {
    super({ api: "sp_checkout" });
  }
}

export function useEventApi() {
  const api = new EventApi();

  return {
    fetchEvent: async (url) => api.get(url),
  };
}
