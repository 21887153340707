<template>
  <div class="sp-bottom-sheet" :class="modifierClasses">
    <slot name="activator" @click.stop="open = true" />
    <sp-dialog
      position="bottom"
      width="100vw"
      :persistent="persistent"
      :open="open"
      :scrollable="scrollable"
      @close="handleDialogClose"
      @update-open="handleDialogUpdateOpen"
    >
      <div class="sp-bottom-sheet__content">
        <div class="sp-bottom-sheet__dialog-slot">
          <slot />
        </div>
        <div class="sp-bottom-sheet__action">
          <slot name="action" @click="open = false" />
        </div>
      </div>
    </sp-dialog>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { toBoolean } from "../../utils/props";
import { props as dialogProps } from "../SpDialog/props.js";
</script>

<script setup>
import { useEventListener } from "@vueuse/core";

const emit = defineEmits(["close", "update-open"]);

const props = defineProps({
  ...dialogProps,
  /**
   * Whether the bottom sheet should expand to maximum height.
   * If `true`, the bottom sheet will expand to maximum height.
   * The maximum height is defined by the `--sp-comp-bottom-sheet-content-max-height` CSS variable.
   *
   * @type {Boolean}
   * @default false
   */
  expandHeightMax: {
    type: [Boolean, String],
    default: false,
  },

  /**
   * Whether the bottom sheet should expand to full height.
   * If `true`, the bottom sheet will expand to full height.
   *
   * @type {Boolean}
   * @default false
   */
  expandToFullHeight: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether the bottom sheet should have no content padding.
   * If `true`, the bottom sheet will have no content padding.
   *
   * @type {Boolean}
   * @default false
   */
  noContentPadding: {
    type: [Boolean, String],
    default: false,
  },
});

const open = ref(toBoolean(props.open));

watch(
  () => props.open,
  (value) => (open.value = toBoolean(value)),
);

watch(open, (value) => {
  emit("update-open", value);
  if (!value) {
    emit("close");
  }
});

const vh = ref(calculateVh());

function calculateVh() {
  const height = window.visualViewport?.height ?? window.innerHeight;
  return `${height * 0.01}px`;
}

function recalculateVh() {
  vh.value = calculateVh();
}

useEventListener(window, "resize", recalculateVh);
useEventListener(window, "orientationchange", recalculateVh);

onMounted(recalculateVh);

const modifierClasses = computed(() => ({
  "--expand-height-max": toBoolean(props.expandHeightMax),
  "--expand-to-full-height": toBoolean(props.expandToFullHeight),
  "--no-content-padding": toBoolean(props.noContentPadding),
}));

function handleDialogClose() {
  open.value = false;
}

function handleDialogUpdateOpen({ detail }) {
  const [value] = detail;
  open.value = value;
}
</script>

<style>
:host {
  display: block;
  --bottom-sheet-border-radius: var(--sp-comp-bottom-sheet-border-radius, 0);
}
</style>

<style lang="scss" scoped>
.sp-bottom-sheet {
  --vh-multiplier: 94;
  --vh-calculated: calc(v-bind(vh) * var(--vh-multiplier));
  --bottom-sheet-max-height: var(--sp-comp-bottom-sheet-content-max-height, var(--vh-calculated));
  --dialog-height: var(--bottom-sheet-max-height);
  --dialog-min-height: var(--bottom-sheet-max-height);
  --bottom-sheet-height: auto;

  &.--no-content-padding {
    --sp-comp-bottom-sheet-content-padding-inline: 0 !important;
    --sp-comp-bottom-sheet-content-padding-block: 0 !important;
  }
}

.sp-bottom-sheet.--expand-height-max {
  --bottom-sheet-height: var(--bottom-sheet-max-height);
}

.sp-bottom-sheet.--expand-to-full-height {
  --vh-multiplier: 100;
  --bottom-sheet-height: var(--bottom-sheet-max-height);
  --dialog-min-height: var(--bottom-sheet-max-height);
  --dialog-height: var(--bottom-sheet-max-height);
}

.sp-bottom-sheet__content {
  display: grid;
  grid-template-rows: 1fr auto;
  box-sizing: border-box;
  position: relative;
  max-height: var(--bottom-sheet-max-height);
  min-height: 0;
  height: var(--bottom-sheet-height);
  border-radius: var(--bottom-sheet-border-radius);
  background-color: var(--sp-comp-bottom-sheet-background-color, var(--sp-sys-color-surface, white));
  padding-inline: var(--sp-comp-bottom-sheet-content-padding-inline, 1rem);
  padding-block: var(--sp-comp-bottom-sheet-content-padding-block, 1rem);
}

sp-dialog {
  --sp-comp-dialog-margin: 0;
  --sp-comp-dialog-padding: 0;
  --sp-comp-dialog-background: transparent;
  --sp-comp-dialog-width: 100vw;
  --sp-comp-dialog-border-radius: var(--bottom-sheet-border-radius);
}

.sp-bottom-sheet__dialog-slot {
  position: relative;
  overflow: hidden;
}

.sp-bottom-sheet__action {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-inline: var(--sp-comp-bottom-sheet-action-container-padding-inline, 1rem);
  ::slotted(*) {
    margin-top: 1rem;
  }
}
</style>
