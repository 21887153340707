<template>
  <sp-button
    ref="root"
    class="sp-stepper-item"
    :disabled="!editable"
    stacked
    color="default"
    :class="rootClassModifiers"
  >
    <slot>
      <div class="container">
        <div class="sp-stepper-item__title">{{ title }}</div>
        <div class="sp-stepper-item__status" :class="classModifiers">
          <sp-icon name="check-filled" size="3xs" fill-color="white" />
        </div>
        <hr v-if="showPrependDivider" class="sp-stepper-item__divider --prepend" part="stepper-item-divider-prepend" />
        <hr v-if="showAppendDivider" class="sp-stepper-item__divider --append" part="stepper-item-divider-append" />
      </div>
    </slot>
  </sp-button>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { toBoolean } from "../../utils/props";

const props = defineProps({
  title: {
    type: String,
    default: undefined,
  },
  value: {
    type: [String, Number, Object, Array, Boolean],
    default: undefined,
  },
  active: {
    type: [Boolean, String],
    default: false,
  },
  completed: {
    type: [Boolean, String],
    default: false,
  },
  editable: {
    type: [Boolean, String],
    default: false,
  },
  isFirst: {
    type: [Boolean, String],
    default: false,
  },
  isLast: {
    type: [Boolean, String],
    default: false,
  },
});

const editable = ref(toBoolean(props.editable));
watch(
  () => props.editable,
  () => (editable.value = toBoolean(props.editable)),
);
const root = ref(null);
const { isGtSmScreen } = useBreakpoints(root);

const classModifiers = computed(() => ({
  "--active": toBoolean(props.active),
  "--completed": toBoolean(props.completed),
}));

const rootClassModifiers = computed(() => ({
  "--bp-gt-sm": isGtSmScreen.value,
}));

const showPrependDivider = computed(() => !toBoolean(props.isFirst));
const showAppendDivider = computed(() => !toBoolean(props.isLast));
</script>

<style>
:host {
  display: block;
}
</style>

<style scoped lang="scss">
.sp-stepper-item {
  --stepper-item-status-color: var(--sp-comp-stepper-item-status-color, #d8d8d8);
  --stepper-item-status-color-active: var(--sp-comp-stepper-item-status-color-active, #4da86d);
  --stepper-item-status-color-completed: var(--sp-comp-stepper-item-status-color-completed, #4da86d);
  --stepper-item-status-size: 1.375rem;
  --stepper-item-title-display: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
  text-align: center;
  display: grid;
  position: relative;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.--bp-gt-sm {
    --stepper-item-title-display: block;
  }
}
.sp-stepper-item__divider {
  display: block;
  width: calc(50% + var(--header-stepper-items-gap) / 2);
  height: 2px;
  position: absolute;
  border: none;
  box-shadow: none;
  margin: 0;
  bottom: calc(var(--stepper-item-status-size) / 2);
  z-index: 1;

  &.--prepend {
    left: calc(var(--header-stepper-items-gap) / -2);
    background: var(--stepper-item-divider-prepend-color, #ccc);
  }

  &.--append {
    right: calc(var(--header-stepper-items-gap) / -2);
    background: var(--stepper-item-divider-append-color, #ccc);
  }
}

.sp-stepper-item__status {
  width: var(--stepper-item-status-size);
  height: var(--stepper-item-status-size);
  border-radius: 50%;
  background-color: var(--stepper-item-status-color);
  margin-top: var(--sp-ref-spacing-3);
  z-index: 3;
  color: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;

  sp-icon {
    opacity: 0;
  }

  &.--active {
    --stepper-item-status-color: var(--stepper-item-status-color-active);
    color: white;

    & + .sp-stepper-item__divider {
      --stepper-item-divider-prepend-color: var(--stepper-item-status-color-active);
    }
  }

  &.--completed {
    --stepper-item-status-color: var(--stepper-item-status-color-completed);
    color: white;

    & + .sp-stepper-item__divider,
    & + .sp-stepper-item__divider + .sp-stepper-item__divider {
      --stepper-item-divider-prepend-color: var(--stepper-item-status-color-completed);
      --stepper-item-divider-append-color: var(--stepper-item-status-color-completed);
    }
    sp-icon {
      opacity: 1;
      --fill: white;
    }
  }
}

.sp-stepper-item__title {
  display: var(--stepper-item-title-display);
  font: var(--sp-sys-typescale-footnote-bold-short);
}

sp-button {
  --sp-comp-button-disabled-opacity: 1;
  --sp-comp-button-text-transform: uppercase;
  --sp-comp-button-font-size: 0.8125rem;
  --sp-comp-button-font-family: var(--sp-sys-font-family-medium);
  --sp-comp-button-font-weight: var(--sp-sys-font-weight-medium);
  --sp-comp-button-text-color: var(--sp-comp-stepper-item-text-color, var(--stepper-item-status-color-active));
  --sp-comp-button-gap: 0;
  --sp-comp-button-bg-color: transparent;

  &::part(base) {
    padding: 0 !important;
    min-width: fit-content;
  }
}
</style>
