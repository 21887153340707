<template>
  <BasePanel ref="root" :preamble="preamble">
    <div ref="slotContainer" class="form-panel">
      <slot />
    </div>
  </BasePanel>
</template>

<script setup>
import { useEventListener } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { usePurchaseStore } from "../store/purchase";
import { prepareEligibilityValidation } from "../utils/form";
import BasePanel from "./BasePanel.ce.vue";

/**
 * Form Builder specific selectors.
 * These selectors are used to identify the form groups, labels etc.
 */
const FormBuilderSelectors = {
  GROUP: "[data-group=field-input-group]",
  GROUP_LABEL: "[data-group=field-input-group-label]",
  FIELD_HEADER_TITLE: "[data-group=field-header-title]",
};

const emit = defineEmits(["scroll-to-top"]);

defineOptions({
  inheritAttrs: false,
});

defineExpose({
  prev,
  next,
});

const purchaseStore = usePurchaseStore();

const root = ref(null);

const slotContainer = ref(null);

const formSlot = ref(null);
const formElement = ref(null);
const currentGroupIndex = ref(0);

watch(slotContainer, (value) => {
  formSlot.value = value.querySelector("slot");
});

/**
 * In order to access the form element, we need to wait for the slot to be populated.
 * We listen for the slotchange event, extract the form element, and store it in the form element ref.
 */
useEventListener(formSlot, "slotchange", () => {
  const slotContent = formSlot.value?.assignedElements?.()?.at(0);
  formElement.value = slotContent?.querySelector("form");

  removeUnwantedFormElements();
  hideUnwantedFormElements();

  populateFormFields();

  purchaseStore.setFormElement(formElement.value);
});

function removeUnwantedFormElements() {
  formElement.value?.querySelector("[type=submit]")?.remove();
  formElement.value?.querySelector("[data-behaviour=preview-form]")?.remove();
}

function hideUnwantedFormElements() {
  // Hide the group labels. Their content is used as the preamble.
  const formGroupLabels = formElement.value?.querySelectorAll(
    [FormBuilderSelectors.GROUP_LABEL, FormBuilderSelectors.FIELD_HEADER_TITLE].join(", "),
  );
  formGroupLabels?.forEach((label) => {
    label.style.display = "none";
  });
}

/**
 * Populate the form fields with the values from the store.
 * This is useful when the user navigates from an email link and we have the form fields enterered already.
 * The form fields are fetched from an API and stored in the store.
 *
 * If the form fields are not available, this function does nothing.
 */
function populateFormFields() {
  const { formFieldsFlattened } = purchaseStore;

  if (!formFieldsFlattened.value) {
    return;
  }

  formFieldsFlattened.value.forEach((field) => {
    const input = formElement.value?.querySelector(`[name$="${field.name}"]`);

    if (!input) {
      return;
    }

    input.value = field.value;
  });
}

/**
 * Intercept the submit event and prevent the default behaviour.
 * This allows us to handle the form submission ourselves.
 * @param {Event} e
 */
useEventListener(formElement, "submit", (e) => {
  e.preventDefault();
  e.stopPropagation();
});

const groups = computed(() => {
  if (!formElement.value) {
    return [];
  }
  return Array.from(formElement.value.querySelectorAll(FormBuilderSelectors.GROUP));
});

const currentGroup = computed(() => groups.value[currentGroupIndex.value]);

watch([groups, currentGroup], () => {
  adjustGroupsVisibility();
  emit("scroll-to-top");
});

/**
 * Adjust the visibility of the groups.
 * The current group is visible and the other groups are hidden.
 *
 * We do not use display: none because we want the form fields to be accessible/focusable.
 * Otherwise the native form validation will not work and throw an error.
 */
function adjustGroupsVisibility() {
  groups.value.forEach((group) => adjustGroupVisibility(group, group === currentGroup.value));
}

/**
 * Adjust the visibility of the group.
 * @param {Element} group
 * @param {boolean} isVisible
 */
function adjustGroupVisibility(group, isVisible) {
  group.classList.toggle("--hidden", !isVisible);
}

function findFormElementInCurrentGroup(selector) {
  return currentGroup.value?.querySelector(selector);
}

const currentGroupTitleProviderElement = computed(
  () =>
    findFormElementInCurrentGroup(FormBuilderSelectors.FIELD_HEADER_TITLE) ??
    findFormElementInCurrentGroup(FormBuilderSelectors.GROUP_LABEL),
);

/**
 * The preamble is the text that appears above the form.
 * If the current group contains an element which provides the preamble, we use that element's text content.
 *
 * @type {import("vue").ComputedRef<string>}
 */
const preamble = computed(() => currentGroupTitleProviderElement.value?.textContent);

/**
 * Get all the form fields that are validatable.
 * @type {import("vue").ComputedRef<Element[]>}
 */
const validatableFormFields = computed(() =>
  Array.from(formElement.value?.elements ?? []).filter((element) => isValidatableElement(element)),
);

/**
 * Check if the element is a validatable element.
 *
 * Other elements like buttons, hidden fields, and disabled fields are not validatable or at least we
 * don't want to validate them.
 *
 * @param {Element} element
 * @returns {boolean}
 */
function isValidatableElement(element) {
  if (element.disabled) {
    return false;
  }

  const invalidTags = ["BUTTON", "OUTPUT"];
  if (invalidTags.includes(element.tagName)) {
    return false;
  }

  const invalidTypes = ["button", "submit", "reset", "hidden"];
  if (invalidTypes.includes(element.type)) {
    return false;
  }

  const closestSelectors = ["fieldset[disabled]"];
  if (closestSelectors.some((selector) => element.closest(selector))) {
    return false;
  }

  return true;
}

const formFieldsOfCurrentGroup = computed(() =>
  validatableFormFields.value.filter((field) => field.closest(FormBuilderSelectors.GROUP) === currentGroup.value),
);

/**
 * Report the validity of the current group.
 * If at least one field is invalid, the function will return false.
 *
 * @returns {boolean}
 */
function reportValidityOfCurrentGroup() {
  prepareEligibilityValidation(currentGroup.value);
  return !formFieldsOfCurrentGroup.value.some((element) => !element.reportValidity());
}

const hasPrevGroup = computed(() => currentGroupIndex.value > 0);
const hasNextGroup = computed(() => currentGroupIndex.value < groups.value.length - 1);

/**
 * Navigate to the previous group.
 * The function must return a boolean.
 * True means that the navigation was handled internally otherwise the navigation will be handled by the caller.
 */
function prev() {
  if (!hasPrevGroup.value) {
    return false;
  }
  currentGroupIndex.value--;
  return true;
}

/**
 * Navigate to the next group.
 * The function must return a boolean.
 * True means that the navigation was handled internally otherwise the navigation will be handled by the caller.
 *
 * If the current group is not valid, the function will return true to prevent the navigation.
 * That way we prevent the user from navigating to the next group if the current group is not valid.
 */
function next() {
  if (!reportValidityOfCurrentGroup()) {
    return true;
  }

  if (!hasNextGroup.value) {
    return false;
  }

  currentGroupIndex.value++;
  return true;
}
</script>

<style scoped>
.form-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: var(--sp-ref-spacing-12);
}
</style>
