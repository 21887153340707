<template>
  <component
    :is="componentName"
    ref="root"
    type="event"
    :add-to-cart-url="addToCartUrl"
    :resource.prop="event"
    :payment="paymentAllowed"
    :open="open"
    review
    mobile-cta-target
    @update-open="handleModalUpdateOpen"
  >
    <div slot="form">
      <sp-card-content-overlay slot="content-overlay" visible>
        <sp-animated-ellipsis size="large" color="primary" />
      </sp-card-content-overlay>
    </div>
  </component>
</template>

<script>
const componentTypes = {
  checkout: {
    component: "sp-checkout",
  },
  waitlist: {
    component: "sp-waitlist",
  },
};
</script>

<script setup>
/**
 * This component is used to display the event registration form in a modal.
 * It utilizes the `sp-checkout` component to display the event details and registration form.
 */
import { computed, ref, watch } from "vue";
import { useHost } from "../../composables/host";
import { toBoolean, toJSON } from "../../utils/props";
import { useEventApi } from "./api/event";

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => Object.keys(componentTypes).includes(value),
  },
  eventId: {
    type: String,
    default: undefined,
  },
  url: {
    type: String,
    default: undefined,
  },
  open: {
    type: Boolean,
    default: false,
  },
  supportCSSUrl: {
    type: String,
    external: true,
    default: undefined,
  },
});

const api = useEventApi();

const componentType = computed(() => componentTypes[props.type]);
const componentName = computed(() => componentType.value?.component);

const root = ref(null);
const open = ref(toBoolean(props.open));
const event = ref(null);

const { rootNode } = useHost(root);

const addToCartUrl = computed(() => {
  if (!event.value) {
    return;
  }

  return `/purchases/add_to_cart?resource_type=Event&resource_id=${event.value.id}`;
});

const paymentAllowed = computed(() => event.value?.is_online_payment_allowed);

function handleModalUpdateOpen({ detail }) {
  const [value] = detail;
  open.value = value;
}

/**
 * Lazily fetches the event data when the modal is opened.
 * If the event data is already present, it will not fetch it again.
 */
watch(open, async (value) => {
  if (value && !event.value) {
    await fetchEvent();
  }
});

async function fetchEvent() {
  const data = await api.fetchEvent(props.url);
  event.value = data.attributes;

  // convert the escaped HTML string to an unescaped string
  const registrationFormHTML = toJSON(data.registration_form);
  prepareFormSlot(registrationFormHTML);
}

/**
 * Prepares the form slot with the form elements.
 * It also appends the required CSS for the form.
 * @param registrationFormHTML - The registration form HTML string
 */
function prepareFormSlot(registrationFormHTML) {
  loadFormBuilderCSS();

  const formElements = extractFormElements(registrationFormHTML);
  formElements.classList.add("--registration-form");
  hydrateFormSlot(formElements);
}

function loadFormBuilderCSS() {
  const styleLinkTag = document.createElement("link");
  styleLinkTag.rel = "stylesheet";
  styleLinkTag.href = props.supportCSSUrl;
  rootNode.value.appendChild(styleLinkTag);
}

/**
 * Extracts the form elements from the registration form HTML string.
 *
 * @param registrationFormHTML - The HTML string of the registration form
 * @returns {HTMLElement} - The form elements extracted from the HTML string
 */
function extractFormElements(registrationFormHTML) {
  // intermedate step to convert the form string to DOM elements
  const container = document.createElement("div");
  container.innerHTML = registrationFormHTML;

  // extract the form elements from the container
  return container.querySelector(".form-builder-container");
}

/**
 * Hydrates the form slot with the form elements.
 * @param formElements - The form elements to hydrate the form slot with
 */
function hydrateFormSlot(formElements) {
  const slot = root.value.querySelector("[slot=form]");

  formElements.setAttribute("slot", "form");
  root.value.replaceChild(formElements, slot);
}
</script>
