import { findRootContext } from "./context";

const localContext = {};

/**
 * Creates a store that can be shared across multiple instances of a custom element.
 * If shared is set to true, the store will be shared across all instances of the custom element.
 * Otherwise, the store will be shared only within the same instance of the custom element.
 *
 * @param {String} id - unique identifier for the store
 * @param {Function} setup - function that returns an initialized store
 * @param {boolean} shared - whether the store should be shared across all instances of the custom element.
 * @returns Function - a function that returns the store
 */
export function defineStore(id, setup, shared = false) {
  return function () {
    const context = shared ? localContext : findRootContext();
    context.__stores ??= {};
    context.__stores[id] ??= setup(context.uid);
    return context.__stores[id];
  };
}
