import { computed, ref, watch } from "vue";

import EventCartPanelDetails from "../components/EventCartPanelDetails.ce.vue";
import EventReviewPanel from "../components/EventReviewPanel.ce.vue";
import FormPanel from "../components/FormPanel.ce.vue";
import PaymentPanel from "../components/PaymentPanel.ce.vue";
import ReviewPanel from "../components/ReviewPanel.ce.vue";

import { watchImmediate } from "@vueuse/core";
import { toBoolean } from "../../../utils/props";
import CartPanel from "../components/CartPanel.ce.vue";
import { usePurchaseStore } from "../store/purchase";

const StepperKeys = {
  CART: "cart",
  FORM: "form",
  PAYMENT: "payment",
  REVIEW: "review",
};

export function useCheckout(props) {
  const purchaseStore = usePurchaseStore();
  const isEvent = computed(() => props.type === "event");
  const selectedStep = ref(0);
  const completedSteps = ref({});

  const hasPayment = ref(toBoolean(props.payment));
  watch(
    () => props.payment,
    (value) => (hasPayment.value = toBoolean(value)),
  );

  watchImmediate(hasPayment, (value) => purchaseStore.setPaymentEnabled(value));

  const hasReview = ref(toBoolean(props.review));
  watch(
    () => props.review,
    (value) => (hasReview.value = toBoolean(value)),
  );

  const components = computed(() => {
    if (isEvent.value) {
      return {
        details: EventCartPanelDetails,
      };
    }
    return {
      details: "div",
    };
  });

  const items = computed(() => {
    const defaults = {
      [StepperKeys.CART]: {
        title: "Cart",
        key: StepperKeys.CART,
        component: CartPanel,
        preamble: "Cart summary",
      },
      [StepperKeys.FORM]: {
        title: "Details",
        key: StepperKeys.FORM,
        component: FormPanel,
      },
      [StepperKeys.PAYMENT]: {
        title: "Payment",
        key: StepperKeys.PAYMENT,
        component: PaymentPanel,
        preamble: "Add a payment method",
      },
      [StepperKeys.REVIEW]: {
        title: "Review",
        key: StepperKeys.REVIEW,
        component: ReviewPanel,
        preamble: "Review your data",
      },
    };

    if (isEvent.value) {
      return {
        ...defaults,
        [StepperKeys.FORM]: {
          ...defaults.form,
          title: "Participant",
          preamble: "Who will be attending?",
        },
        [StepperKeys.REVIEW]: {
          ...defaults.review,
          component: EventReviewPanel,
          preamble: "Review your registration",
        },
      };
    }

    return defaults;
  });

  const condtionalStepperItems = computed(() => {
    const conditionalItems = [];
    if (hasPayment.value) {
      conditionalItems.push(items.value.payment);
    }
    if (hasReview.value) {
      conditionalItems.push(items.value.review);
    }
    return conditionalItems;
  });

  const stepperItems = computed(() => [items.value.cart, items.value.form, ...condtionalStepperItems.value]);

  const stepper = computed(() => {
    const defaults = {
      prevText: "Go Back",
      nextText: "Continue",
      finalStepText: purchaseStore.paymentRequired.value ? "Pay" : "Finish",
    };

    if (isEvent.value) {
      return {
        ...defaults,
        finalStepText: purchaseStore.paymentRequired.value ? "Register and Pay" : "Register",
      };
    }

    return defaults;
  });

  /**
   * Go to a specific step in the stepper.
   * If the key is not found, nothing happens.
   *
   * @param {string} key
   * @returns {void}
   */
  function gotoStep(key) {
    const index = stepperItems.value.findIndex((item) => item.key === key);
    if (index !== -1) {
      selectedStep.value = index;
    }
  }

  /**
   * Mark a step as completed.
   *
   * @param {String} key - The key of the step to complete.
   * @returns {void}
   */
  function completeStep(key) {
    updateStepCompleted(key, true);
  }

  /**
   * Reset a step to incomplete.
   *
   * @param {String} key - The key of the step to reset.
   * @returns {void}
   */
  function resetStep(key) {
    updateStepCompleted(key, false);
  }

  /**
   * Reset all steps to incomplete.
   *
   * @returns {void}
   */
  function resetSteps() {
    completedSteps.value = {};
  }

  function updateStepCompleted(key, completed) {
    const item = stepperItems.value.find((item) => item.key === key);

    if (item) {
      completedSteps.value[item.key] = completed;
    }
  }

  return {
    components,
    items,
    stepper,
    stepperItems,
    selectedStep,
    completedSteps,
    hasPayment,
    hasReview,

    StepperKeys,

    gotoStep,
    completeStep,
    resetStep,
    resetSteps,
  };
}
