<template>
  <sp-card class="panel">
    <sp-heading v-if="preamble" slot="title" level="2" class="panel__preamble" :class="classModifiers">
      {{ preamble }}
    </sp-heading>
    <div class="panel__content">
      <slot />
    </div>
  </sp-card>
</template>

<script setup>
import { computed } from "vue";
import { useBreakpoints } from "../../../composables/breakpoints";
defineProps({
  preamble: {
    type: String,
    default: undefined,
  },
});
const { isSmScreen } = useBreakpoints();
const classModifiers = computed(() => ({
  "--is-sm-screen": isSmScreen.value,
}));
</script>

<style scoped lang="scss">
.panel__preamble {
  text-align: center;
  margin-top: var(--sp-ref-spacing-16);

  &.--is-sm-screen {
    margin-top: var(--sp-ref-spacing-10);
    margin-bottom: var(--sp-ref-spacing-6);
  }
}

.panel__content {
  margin-top: var(--sp-ref-spacing-16);
  overflow-x: hidden;
}
</style>
