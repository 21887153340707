import { computed } from "vue";
import { register } from "../src/custom_elements";

const props = {
  SpPaymentElement: {
    appearance: {
      theme: "stripe",
    },
  },
  SpOmnisearch: {
    autocompleteJsUrl: computed(() => window.sparkleOmniSearchAutocompleteJsUrl),
    autocompleteCSSUrl: computed(() => window.sparkleOmniSearchAutocompleteCSSUrl),
  },
  SpEventRegistration: {
    get supportCSSUrl() {
      return window.sparkleSpFormSupportCSSUrl;
    },
  },
  SpGoogleMapsAutoComplete: {
    get apiKey() {
      return window.Sparkle.apiKeys.googleMaps;
    },
  },
  embedHost: window.sparkleEmbedApiHost,
};

register(props);
