/**
 * Convert a string value to a boolean
 * If the value is "true", it returns true, otherwise false
 * If the value is already a boolean, it is returned as is
 *
 * @param {*} value - value to be converted to boolean
 * @returns boolean value
 */
export function toBoolean(value) {
  return value === "true" || value === true;
}

/**
 * Convert a string value to a number
 * If the value can not be parsed, undefined is returned
 * If the value can not be parsed, but returnValue is true, the value is returned
 *
 * @param {String} value - value to be converted
 * @param {Boolean} returnValue - return the value if it can not be parsed
 * @returns {Object|undefined}
 */
export function toJSON(value, returnValue = false) {
  try {
    if (typeof value === "string") {
      return JSON.parse(value);
    }
  } catch (err) {
    console.log(err);
  }
  if (returnValue) {
    return value;
  }
}

/**
 * Trim a string value and return undefined if the value is empty
 * If the value is not a string, it is returned as is
 *
 * @param {*} value - value to be trimmed
 * @returns value trimmed or undefined if value is empty
 */
export function trimToUndefined(value) {
  if (typeof value === "string") {
    return value.trim() || undefined;
  }
  return value;
}

/**
 * Trim a string value and return null if the value is empty
 * If the value is not a string, it is returned as is
 *
 * @param {*} value - value to be trimmed
 * @returns value trimmed or null if value is empty
 */
export function trimToNull(value) {
  return trimToUndefined(value) ?? null;
}

export function toArray(value) {
  return Array.isArray(value) ? value : [value];
}
