<template>
  <div ref="root" class="sp-stepper-actions" part="base" :class="classModifiers">
    <slot name="prev" @click="emit('prev')">
      <sp-button
        part="prev-action"
        :block="isBlockType"
        :disabled="isPrevDisabled"
        :variant="prevActionVariant"
        tabindex="0"
      >
        <sp-icon v-if="isSmScreen" slot="prepend" name="chevron-left-filled" />
        {{ prevText }}
      </sp-button>
    </slot>

    <slot name="next" @click="emit('next')">
      <sp-button part="next-action" :block="isBlockType" :disabled="isNextDisabled" :loading="loading" tabindex="0">
        {{ nextText }}
      </sp-button>
    </slot>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useBreakpoints } from "../../composables/breakpoints";
import { toBoolean } from "../../utils/props";

const props = defineProps({
  prevText: {
    type: String,
    default: "Prev",
  },
  nextText: {
    type: String,
    default: "Next",
  },
  /**
   * Whether the actions are disabled
   *
   * @type {Boolean|String} - true, false, "prev", "next"
   * @default false
   */
  disabled: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Whether the next action is loading
   *
   * @type {Boolean}
   * @default false
   */
  loading: {
    type: [Boolean, String],
    default: false,
  },
});

const emit = defineEmits(["prev", "next"]);

const isDisabled = computed(() => toBoolean(props.disabled));
const isPrevDisabled = computed(() => props.disabled === "prev" || isDisabled.value);
const isNextDisabled = computed(() => props.disabled === "next" || isDisabled.value);

const loading = ref(toBoolean(props.loading));
watch(
  () => props.loading,
  () => (loading.value = toBoolean(props.loading)),
);

const root = ref(null);
const { isGtSmScreen, isSmScreen } = useBreakpoints(root);

const isBlockType = computed(() => isBlockType.value);

const classModifiers = computed(() => ({
  "--bp-gt-sm": isGtSmScreen.value,
  "--is-sm-screen": isSmScreen.value,
}));
const prevActionVariant = computed(() => (isSmScreen.value ? "text-inline" : "outlined"));
</script>

<style>
:host {
  display: block;
  width: 100%;
}
</style>

<style scoped lang="scss">
.sp-stepper-actions {
  --flex-direction: column;
  --gap: var(--sp-comp-stepper-actions-gap, 0);
  display: flex;
  flex-direction: var(--flex-direction);
  justify-content: space-between;
  position: relative;
  gap: var(--gap);
  border-top: var(--sp-comp-stepper-actions-border-top, none);
  padding-block: var(--sp-ref-spacing-8);
  padding-inline: var(--sp-ref-spacing-8);
  box-shadow: var(--sp-comp-stepper-actions-box-shadow, none);

  &.--bp-gt-sm {
    --flex-direction: row;
    --gap: var(--sp-comp-stepper-actions-gap-gt-sm, 1rem);
  }

  sp-button::part(prev-action) {
    text-align: center;
    --button-width: 100%;
  }
}
</style>
