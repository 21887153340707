<template>
  <div ref="component" class="sp-expandable-list" :class="classModifiers">
    <div ref="content" class="sp-expandable-list__content" :style="contentCSSProperties">
      <slot />
    </div>
    <div v-if="showActivator" class="sp-expandable-list__activator" @click="onActivatorClick">
      <slot name="activator">
        <sp-button ref="activatorButton" :label="buttonLabel" :variant="variant" :size="activatorSize" />
      </slot>
    </div>
  </div>
</template>

<script setup>
/**
 * The SpExpandableList component is used to display a list of items that can be expanded or collapsed.
 *
 * @displayName ExpandableList
 * @group Custom Elements
 * @component sp-expandable-list
 */
import { onClickOutside } from "@vueuse/core";
import { computed, onMounted, ref, watch } from "vue";
import SpButton from "../SpButton/SpButton.ce.vue";

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(["update-expanded"]);

const props = defineProps({
  /**
   * Whether the list is expanded or not.
   *
   * @type {Boolean}
   * @default false
   */
  expanded: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * The title to display when the list is collapsed.
   *
   * @type {String}
   * @default "Show more"
   */
  title: {
    type: String,
    default: "Show more",
  },
  /**
   * The title to display when the list is expanded.
   *
   * @type {String}
   * @default "Show less"
   */
  expandedTitle: {
    type: String,
    default: "Show less",
  },
  /**
   * The maximum number of items to display.
   * If the number of items exceeds the limit, the list will be collapsed.
   * If the limit is not defined, all items will be displayed and the activator button will not be shown.
   *
   * @type {Number}
   * @default undefined
   */
  limit: {
    type: Number,
    default: undefined,
  },
  /**
   * The number of columns to display the items in.
   * If not defined, the items will be displayed in a single column.
   *
   * @type {Number}
   * @default undefined
   */
  columns: {
    type: Number,
    default: undefined,
  },
  /**
   * The variant of the activator button.
   * See the SpButton component for available variants.
   *
   * @type {String}
   * @default "text-inline"
   * @see SpButton.props.variant
   */
  variant: {
    type: SpButton.props.variant.type,
    validator: SpButton.props.variant.validator,
    default: "text-inline",
  },
  activatorSize: SpButton.props.size,
  /**
   * Whether to fade the last item when the list is collapsed.
   * If `true`, the last item will fade out when the list is collapsed.
   *
   * @type {Boolean}
   * @default false
   */
  fadeLastItemOnCollapse: {
    type: [Boolean, String],
    default: false,
  },
});

const isExpanded = ref(props.expanded);
watch(isExpanded, (value) => emit("update-expanded", value));

watch(
  () => props.expanded,
  (value) => (isExpanded.value = value),
);

const component = ref(null);
onClickOutside(component, () => (isExpanded.value = false));

const buttonLabel = computed(() => (isExpanded.value ? props.expandedTitle : props.title));

const contentCSSProperties = computed(() => ({
  "--expandable-list-columns": props.columns > 0 ? props.columns : undefined,
}));

const content = ref(null);
const listItems = computed(() => {
  if (content.value?.firstElementChild?.tagName === "SLOT") {
    return content.value.firstElementChild.assignedElements();
  }

  return Array.from(content.value?.children ?? []);
});
const listItemsCount = computed(() => listItems.value.length);

const hasLimitDefined = computed(() => props.limit !== undefined && props.limit > 0);

const exceedsLimit = computed(() => hasLimitDefined.value && listItemsCount.value > props.limit);

const activatorButton = ref(null);
const showActivator = computed(() => exceedsLimit.value);

const classModifiers = computed(() => ({
  "--is-expanded": isExpanded.value,
  "--fade-last-item-on-collapse": props.fadeLastItemOnCollapse,
}));

function onActivatorClick() {
  isExpanded.value = !isExpanded.value;
  activatorButton.value?.blur();
}

function adjustListItemsVisibility() {
  if (!exceedsLimit.value) {
    return;
  }

  listItems.value.slice(props.limit).forEach((item) => {
    item.classList.toggle("sp-expandable-list__item--hidden", !isExpanded.value);
  });
}

watch(
  () => [listItemsCount.value, isExpanded.value],
  () => adjustListItemsVisibility(),
  { immediate: true },
);

onMounted(async () => adjustListItemsVisibility());
</script>

<style>
:host {
  display: block;
}
</style>

<style lang="scss" scoped>
::slotted(.sp-expandable-list__item--hidden) {
  display: none !important;
}

.sp-expandable-list {
  position: relative;
  --content-z-index: var(--sp-comp-expandable-list-content-z-index, 1);

  &.--fade-last-item-on-collapse:not(.--is-expanded) {
    .sp-expandable-list__activator {
      padding-top: 2rem;
      z-index: calc(var(--content-z-index) + 1);
      width: 100%;
      position: absolute;
      bottom: 0;
      background: linear-gradient(to bottom, transparent, white 60%);
    }
  }
}

.sp-expandable-list__content {
  --expandable-list-columns: 1;
  --expandable-list-gap: 15px;

  display: grid;
  grid-auto-rows: auto;
  gap: var(--expandable-list-gap);
  grid-template-columns: repeat(var(--expandable-list-columns), 1fr);
  z-index: var(--content-z-index);
}

sp-button[variant="outlined"] {
  margin-top: var(--sp-comp-expandable-list-variant-outlined-activator-gap, 0.75rem);
  --sp-comp-button-font-size: var(
    --sp-comp-expandable-list-variant-outlined-activator-font-size,
    var(--sp-sys-body-font-size, 0.75rem)
  );
}
</style>
