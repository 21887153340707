<template>
  <ReviewSection :title="title" icon="event-filled">
    <sp-col sm="9">
      <sp-subtitle>{{ event.name }}</sp-subtitle>
      <span>{{ event.formatted_date_range }}</span>
      <span>{{ event.formatted_time_range }}</span>
    </sp-col>
    <sp-col sm="10">
      <template v-if="event.is_in_person || event.is_virtual_and_in_person">
        <div v-if="event.is_virtual_and_in_person">
          <sp-subtitle>Virtual or In-Person</sp-subtitle>
        </div>

        <template v-if="event.address">
          <sp-subtitle>{{ event.address.locale_name }}</sp-subtitle>
          <span>{{ event.address.street_address }}</span>
          <div>
            {{ event.address.city }}, {{ event.address.state }}
            {{ event.address.zip_code }}
          </div>
        </template>
      </template>
      <template v-else-if="event.is_virtual">
        <sp-subtitle>Virtual Event</sp-subtitle>
      </template>
    </sp-col>
  </ReviewSection>
</template>

<script setup>
import { computed } from "vue";
import { usePurchaseStore } from "../store/purchase";
import ReviewSection from "./ReviewSection.ce.vue";

const purchaseStore = usePurchaseStore();

defineEmits(["goto"]);

defineProps({
  title: {
    type: String,
    default: "Date & Time",
  },
});

const event = computed(() => purchaseStore.resource.value);
</script>

<style lang="scss" scoped>
sp-col.--is-sm-screen.--sm-offset-icon {
  margin-inline-start: var(--sp-sys-icon-size-2xl);
}
</style>
