import { unrefElement, useEventListener, useNow } from "@vueuse/core";
import { computed, ref, watch } from "vue";
import { defineStore } from "../../../utils/store";
import { usePurchaseApi } from "../api/purchase";
import { prepareEligibilityValidation } from "../utils/form";

const KEY_FILTER = "registration[fields]";
const ONE_MINUTE = 60;

const paymentTypes = {
  card: {
    icon: "credit-card-color",
    name: "Credit Card",
  },
  paypal: {
    icon: "paypal",
    name: "Paypal",
  },
};

export const usePurchaseStore = (options = {}) =>
  defineStore("purchase", () => {
    const api = options?.api ?? usePurchaseApi();

    const purchaseToken = ref();
    const publishableKey = ref();
    const clientSecret = ref();
    const returnUrl = ref();
    const lineItems = ref();
    const expiresAt = ref();

    const extendExpirationUrl = ref();
    const applyPromoCodeUrl = ref();
    const removePromoCodeUrl = ref();

    const paymentEnabled = ref(false);
    const paymentRequired = ref(false);
    const paymentElement = ref();

    const paymentType = ref();
    const paymentIcon = ref();
    const paymentName = ref();

    const totalAmount = ref(0);
    const discountName = ref();
    const discountAmount = ref(0);
    const promoCode = ref();

    const entries = ref();
    const formElement = ref();
    const formResult = ref();
    const registrationUrl = ref();
    const formFields = ref();

    /**
     * The resource object provided as a prop to the custom element.
     * It represents the resource that is being purchased.
     * It is used to determine the type of payment and render the correct panels.
     *
     * @param {import("vue").Ref<Object>} resource
     */
    const resource = ref();

    function setResource(value) {
      resource.value = value;
    }

    watch(paymentElement, (element) => {
      useEventListener(element, "update-payment-type", ({ detail }) => {
        const [type] = detail;
        paymentType.value = type;
      });
    });

    watch(paymentEnabled, (enabled) => {
      paymentRequired.value = enabled;
    });

    watch(paymentType, (value) => {
      if (value) {
        const { icon, name } = paymentTypes[value] ?? {};
        paymentIcon.value = icon;
        paymentName.value = name;
      }
    });

    function validatePayment() {
      return paymentElement.value.validate();
    }

    async function confirmPayment() {
      return await paymentElement.value.confirmPayment();
    }

    function createEntries(formData) {
      entries.value = Array.from(formData.keys()).reduce((acc, key) => {
        if (key.startsWith(KEY_FILTER)) {
          const nestedKey = key.replace(`${KEY_FILTER}[`, "").replace("]", "");
          acc[nestedKey] = formData.get(key);
        }
        return acc;
      }, {});
    }

    function isValid() {
      prepareEligibilityValidation(formElement.value);

      formElement.value.reportValidity();
      return formElement.value.checkValidity();
    }

    async function addToCart(url) {
      try {
        const response = await api.addToCart(url);

        purchaseToken.value = response.purchase_token;
        publishableKey.value = response.publishable_key;
        clientSecret.value = response.client_secret;
        returnUrl.value = response.return_url;
        lineItems.value = response.line_items ?? [];
        expiresAt.value = new Date(response.expires_at).getTime();
        extendExpirationUrl.value = response.extend_expiration_url;
        applyPromoCodeUrl.value = response.apply_promo_url;
        removePromoCodeUrl.value = response.remove_promo_url;
        formFields.value = response.form_fields;
        registrationUrl.value = response.registration_url;

        updatePaymentValues(response);
      } catch (err) {
        console.log("err", err);
        return null;
      }
    }

    /**
     * A flattened array of form fields, with the name transformed to a string with brackets
     * @type {import("vue").ComputedRef<Array<{ name: string, value: any }>>}
     */
    const formFieldsFlattened = computed(() => {
      const mapper = ({ name, value }) => ({ name: `[fields]${name}`, value });
      return flattenFormFields(formFields.value ?? {}).map(mapper);
    });

    /**
     * convert a deeply nested object to a flat array of the form [{ name: 'name', value: 'value' }]
     * the name is the path to the value in the object, transformed to a string with brackets
     * e.g. { address: { city: 'Berlin' } } => [{ name: '[address][city]', value: 'Berlin' }]
     *
     * @private
     * @param {Object} fields
     * @returns {Array<{ name: string, value: any }>}
     */
    function flattenFormFields(fields) {
      return Object.entries(fields).reduce((acc, [key, value]) => {
        if (typeof value === "object") {
          const mapper = ({ name, value }) => ({ name: `[${key}]${name}`, value });
          return [...acc, ...flattenFormFields(value).map(mapper)];
        }

        return [...acc, { name: `[${key}]`, value }];
      }, []);
    }

    const nowDate = useNow();
    const now = computed(() => nowDate.value.getTime());

    const isExpired = computed(() => {
      if (!expiresAt.value) {
        return false;
      }

      return now.value > expiresAt.value;
    });

    const secondsUntilExpiration = computed(() => {
      if (isExpired.value) {
        return 0;
      }
      return Math.floor((expiresAt.value - now.value) / 1000);
    });

    const expiresWithinSeconds = computed(() => expiresAt.value && secondsUntilExpiration.value <= ONE_MINUTE);

    async function extendExpiration() {
      const response = await api.extendExpiration(extendExpirationUrl.value);
      expiresAt.value = new Date(response.expires_at).getTime();
    }

    async function submitForm() {
      const formData = new FormData(formElement.value);
      createEntries(formData);

      formData.append("purchase_token", purchaseToken.value);
      formData.append("embedded_at_url", window.location.href);

      try {
        if (!registrationUrl.value) {
          formResult.value = await api.submitForm(formElement.value.action, formData);
          registrationUrl.value = formResult.value.url;
        } else {
          formResult.value = await api.updateForm(registrationUrl.value, formData);
        }
        updatePaymentValues(formResult.value);

        return true;
      } catch (err) {
        return null;
      }
    }

    async function applyPromoCode(code) {
      discountAmount.value = 0;

      try {
        const response = handlePromoApiResponse(await api.applyPromoCode(applyPromoCodeUrl.value, code));
        promoCode.value = code;
        return response;
      } catch (err) {
        return handlePromoApiResponseError(err, "Unable to apply promo code");
      }
    }

    async function removePromoCode() {
      try {
        const response = handlePromoApiResponse(await api.removePromoCode(removePromoCodeUrl.value));
        promoCode.value = undefined;
        return response;
      } catch (err) {
        console.log("err", err);
        return handlePromoApiResponseError(err, "Unable to remove promo code");
      }
    }

    function handlePromoApiResponse(response) {
      updatePaymentValues(response);

      return { error: undefined };
    }

    function handlePromoApiResponseError(err, message) {
      return { error: err?.response?.data?.error ?? message };
    }

    function updatePaymentValues(values) {
      const { payment_required, payment_amount, discount_name, discount_amount } = values ?? {};

      paymentRequired.value = payment_required;
      totalAmount.value = payment_amount;
      discountName.value = discount_name;
      discountAmount.value = discount_amount;
    }

    function reset() {
      purchaseToken.value = undefined;
      formResult.value = undefined;
      formElement.value?.reset();
      formFields.value = undefined;
      expiresAt.value = undefined;
    }

    return {
      entries,
      resource,

      setFormElement: (element) => (formElement.value = element),

      get formElement() {
        return formElement.value;
      },

      get formResult() {
        return formResult.value;
      },

      formFields,
      formFieldsFlattened,

      isValid,
      addToCart,
      submitForm,
      applyPromoCode,
      removePromoCode,
      reset,

      purchaseToken,
      publishableKey,
      clientSecret,
      lineItems,
      returnUrl,

      expiresAt,
      isExpired,
      expiresWithinSeconds,
      secondsUntilExpiration,
      extendExpiration,

      paymentEnabled,
      paymentType,
      paymentIcon,
      paymentName,

      paymentRequired,
      totalAmount,
      discountName,
      discountAmount,
      promoCode,

      setResource,

      setPaymentEnabled: (enabled) => (paymentEnabled.value = enabled),
      setPaymentElement: (element) => (paymentElement.value = unrefElement(element)),

      validatePayment,
      confirmPayment,

      get hasPurchaseToken() {
        return purchaseToken.value !== undefined;
      },

      get hasPaymentData() {
        return paymentType.value;
      },

      get hasDiscount() {
        return discountAmount.value;
      },
    };
  })();
